import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"

import "../styles/pages/Support.scss"

import Layout from "../components/Layout.jsx"
import Seo from "../components/Seo"

export default function Support() {
  const intl = useIntl()
  const [tab, setTab] = useState(0)

  const tab0 = tab === 0
  const tab1 = tab === 1
  const tab2 = tab === 2
  const tab3 = tab === 3
  const tab4 = tab === 4
  const tab5 = tab === 5
  const tab6 = tab === 6

  const handleTab = numberTab => () => {
    setTab(numberTab)
  }

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "support-seo" })} />
      <main className="support">
        <section className="support__header">
          <div className="support__header-content">
            <button
              type="button"
              onClick={handleTab(0)}
              className="support__header-tab"
              data-active={tab === 0}
            >
              {intl.formatMessage({ id: "support-title-one" })}
            </button>
            <button
              type="button"
              onClick={handleTab(1)}
              className="support__header-tab"
              data-active={tab === 1}
            >
              {intl.formatMessage({ id: "support-title-two" })}
            </button>
            <button
              type="button"
              onClick={handleTab(2)}
              className="support__header-tab"
              data-active={tab === 2}
            >
              {intl.formatMessage({ id: "support-title-three" })}
            </button>
            <button
              type="button"
              onClick={handleTab(3)}
              className="support__header-tab"
              data-active={tab === 3}
            >
              {intl.formatMessage({ id: "support-title-four" })}
            </button>
            <button
              type="button"
              onClick={handleTab(4)}
              className="support__header-tab"
              data-active={tab === 4}
            >
              {intl.formatMessage({ id: "support-title-five" })}
            </button>
            <button
              type="button"
              onClick={handleTab(5)}
              className="support__header-tab"
              data-active={tab === 5}
            >
              {intl.formatMessage({ id: "support-title-six" })}
            </button>
            <button
              type="button"
              onClick={handleTab(6)}
              className="support__header-tab"
              data-active={tab === 6}
            >
              {intl.formatMessage({ id: "support-title-seven" })}
            </button>
          </div>
        </section>
        <section className="support__content">
          {tab0 && (
            <>
              <h1 className="support__content-title">
                {intl.formatMessage({ id: "support-title-one" })}
              </h1>
              <p className="support__content-text">
                {intl.formatMessage({ id: "support-text-one" })}
              </p>
            </>
          )}
          {tab1 && (
            <>
              <h1 className="support__content-title">
                {intl.formatMessage({ id: "support-title-two" })}
              </h1>
              <p className="support__content-text">
                {intl.formatMessage({ id: "support-text-two" })}
              </p>
            </>
          )}
          {tab2 && (
            <>
              <h1 className="support__content-title">
                {intl.formatMessage({ id: "support-title-three" })}
              </h1>
              <p className="support__content-text">
                {intl.formatMessage({ id: "support-text-three" })}
              </p>
            </>
          )}
          {tab3 && (
            <>
              <h1 className="support__content-title">
                {intl.formatMessage({ id: "support-title-four" })}
              </h1>
              <p className="support__content-text">
                {intl.formatMessage({ id: "support-text-four" })}
              </p>
            </>
          )}
          {tab4 && (
            <>
              <h1 className="support__content-title">
                {intl.formatMessage({ id: "support-title-five" })}
              </h1>
              <p className="support__content-text">
                {intl.formatMessage({ id: "support-text-five" })}
              </p>
            </>
          )}
          {tab5 && (
            <>
              <h1 className="support__content-title">
                {intl.formatMessage({ id: "support-title-six" })}
              </h1>
              <p className="support__content-text">
                {intl.formatMessage({ id: "support-text-six" })}
              </p>
            </>
          )}
          {tab6 && (
            <>
              <h1 className="support__content-title">
                {intl.formatMessage({ id: "support-title-seven" })}
              </h1>
              <p className="support__content-text">
                {intl.formatMessage({ id: "support-text-seven" })}
              </p>
            </>
          )}
        </section>
      </main>
    </Layout>
  )
}
